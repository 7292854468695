import React from "react";
import det from "../img/detommasirestyle.webp"
import fred from "../img/fred.png"
import Alex from "../img/lecasedialeloca.webp"

const UltimeNotizie = () => {
  const ultimeNotizie = [
    {
      titolo: "Francesco Felletti",
      testo:
        "Passa a curiosare tra le magie grafiche di Fred!",
      sottotesto: "Qui!",
      data: "6 febbraio 2025",
      immagine: fred,
      link: "https://www.francescofelletti.com",
      linktesto: "Visita il sito!",
    }, 
    {
      titolo: "Giuseppe De Tommasi, collezione 2024",
      testo:
        "Con il Natale alle porte, siamo lieti di presentarti le nuove collezioni del nostro artista.",
      sottotesto: "Passa a trovarlo!",
      data: "2 dicembre 2024",
      immagine: det,
      link: "https://www.lecasedialex.it",
      linktesto: "Visita il sito!",
    },   
    {
      titolo: "Le case vacanze di Alex",
      testo:
        "Ecco un piccolo sito compatto e funzionale per esporre le bellissime case di Alexander!",
      sottotesto: "Passa a trovarlo!",
      data: "15 dicembre 2023",
      immagine: Alex,
      link: "https://www.lecasedialex.it",
      linktesto: "Visita il sito!",
    },   
  ];

  return (
    <section className="py-8">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-3xl font-bold mb-4">Ultime notizie</h2>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
          {" "}
          {/* lg:grid-cols-3 */}
          {ultimeNotizie.map((notizia, index) => (
            <div key={index} className="bg-gradient-to-r from-white via-gray-100 to-gray-300 rounded-lg shadow-md shadow-zinc-900 overflow-hidden p-3">
              <div className="flex justify-center">
                <a href={notizia.link} target="blank">
                <img
                  src={notizia.immagine}
                  alt={notizia.titolo}
                  className="mb-4 rounded w-full cardm"
                />
                </a>
              </div>
              <h3 className="text-xl font-bold mb-2">{notizia.titolo}</h3>
              <p className="text-gray-700 mb-2">{notizia.testo}</p>
              <p className="text-primarypink mb-2">{notizia.sottotesto}</p>
              <p className="text-gray-500 text-sm">{notizia.data}</p>
              <a
                href={notizia.link}
                target="_blank"
                rel="noreferrer"
                className="text-primarypink inline-block mt-2"
              >
                {notizia.linktesto}
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default UltimeNotizie;
